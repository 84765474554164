import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { ROUTE_GROUPS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes';
import { componentWithCondition, componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { globalLayout } from '@atlassian/jira-global-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarAssetsReports from '@atlassian/jira-navigation-apps-sidebar-assets-reports/src/async';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { serviceDeskAssetsImportTypeForgeDataResource } from '@atlassian/jira-router-resources-assets-import-type-forge-data';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { getServicedeskPageContextResources } from '@atlassian/jira-router-resources-service-desk-common';
import { insightObjectResource } from '@atlassian/jira-router-resources-servicedesk-cmdb-object/src/services/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import {
	servicedeskCmdbAssetsRoute,
	servicedeskCmdbInsightRoute,
	servicedeskCmdbAssetsReportsRoute,
	servicedeskCmdbAssetsDefaultRedirect,
	servicedeskCmdbAssetsReportsUnsubscribeRoute,
	servicedeskCmdbAssetsObjectSchema1Route,
	servicedeskCmdbInsightObjectSchemaRoute,
	servicedeskCmdbAssetsObjectTypeConfigurationRoute,
	servicedeskCmdbInsightObjectTypeConfigurationRoute,
	servicedeskCmdbAssetsConfigureRoute,
	servicedeskCmdbInsightConfigureGlobalRoute,
	servicedeskCmdbAssetsObjectSchemaConfigurationRoute,
	servicedeskCmdbInsightObjectSchemaConfigurationRoute,
	servicedeskCmdbInsightConfigureRoute,
	servicedeskCmdbAssetsObject4Route,
	servicedeskCmdbInsightObject4Route,
} from '@atlassian/jira-router-routes-servicedesk-cmdb-routes';
import { toPackageName, APP_NAMES } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import { workspaceResource } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/services/resources/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import InsightConfigure, { LazyServicedeskCmdbConfigure } from './ui/configure';
import ConfigureObjectType, {
	LazyServicedeskCmdbConfigureObjectType,
} from './ui/configure-object-type';
import GlobalConfigure, { LazyServicedeskCmdbGlobalConfigure } from './ui/global-configure';
import LandingPage, { LazyServicedeskCmdbLandingPage } from './ui/landing-page';
import { InsightCmdbObjectViewV2, LazyServicedeskCmdbObjectV2 } from './ui/object';
import { LazyServicedeskCmdbObjectSchemaV2, ObjectSchemaV2 } from './ui/object-schema';
import ObjectSchemaConfiguration, {
	LazyServicedeskCmdbObjectSchemaConfiguration,
} from './ui/object-schema-configuration';
import {
	AssetsReports,
	AssetsReportsUnsubscribe,
	AssetsReportingDefaultRedirect,
	AssetsReportsNav4HomePage,
	LazyServicedeskCmdbAssetsReports,
	LazyServicedeskCmdbAssetsReportsUnsubscribe,
	LazyServicedeskCmdbDefaultReportsRedirect,
	LazyServicedeskCmdbAssetsReportsNav4HomePage,
} from './ui/reports';

const sharedRouteProps = {
	group: ROUTE_GROUPS_SERVICEDESK,
	layout: globalLayout,
	navigation: { menuId: MENU_ID.INSIGHT },
} as const;

function getCmdbResources() {
	const servicedeskPageContextResources = getServicedeskPageContextResources().map((resource) =>
		resourceWithCondition2(() => fg('add_service_desk_page_context_resources'), resource),
	);
	return [...getNavigationResources(), ...servicedeskPageContextResources, workspaceResource];
}

export const insightRouteEntries: Route[] = [
	createEntry(servicedeskCmdbAssetsRoute, () => ({
        ...sharedRouteProps,
        component: LandingPage,
        resources: getCmdbResources(),
        forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbLandingPage],
        perfMetricKey: 'service-management.insight-cmdb.interact-with-landing-page'
    })),
	createEntry(servicedeskCmdbInsightRoute, () => ({
        ...sharedRouteProps,
        component: LandingPage,
        resources: getCmdbResources(),
        forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbLandingPage],
        perfMetricKey: 'service-management.insight-cmdb.interact-with-landing-page'
    })),

	createEntry(servicedeskCmdbAssetsDefaultRedirect, () => ({
        ...sharedRouteProps,

        component: componentWithCondition(
			getWillShowNav4,
			AssetsReportsNav4HomePage,
			AssetsReportingDefaultRedirect,
		),

        resources: [
			...getNavigationResources(),
			...getServicedeskPageContextResources(),
			workspaceResource,
		],

        forPaint: [
			LazyAtlassianNavigation,
			LazyServicedeskCmdbDefaultReportsRedirect,
			LazyServicedeskCmdbAssetsReportsNav4HomePage,
		],

        meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_ASSETS_REPORTS_DEFAULT_REDIRECT,
				packageName: toPackageName(APP_NAMES.INSIGHT_ASSETS_REPORTS_DEFAULT_REDIRECT),
				teamName: 'ITSM SAGA',
			},
		}
    })),

	createEntry(servicedeskCmdbAssetsReportsRoute, () => ({
        ...sharedRouteProps,
        component: componentWithFF('jsm-assets-reporting_seqk0', AssetsReports, ErrorPagesNotFound),

        resources: [
			...getNavigationResources(),
			...getServicedeskPageContextResources(),
			workspaceResource,
		],

        forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbAssetsReports],

        navigation: {
			menuId: MENU_ID.PEOPLE,
			sidebar: AsyncSidebarAssetsReports,
		},

        meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_ASSETS_REPORTS,
				packageName: toPackageName(APP_NAMES.INSIGHT_ASSETS_REPORTS),
				teamName: 'ITSM SAGA',
			},
		}
    })),
	createEntry(servicedeskCmdbAssetsReportsUnsubscribeRoute, () => ({
        ...sharedRouteProps,
        component: AssetsReportsUnsubscribe,

        resources: [
			...getNavigationResources(),
			...getServicedeskPageContextResources(),
			workspaceResource,
		],

        forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbAssetsReportsUnsubscribe],

        meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_ASSETS_REPORTS_UNSUBSCRIBE,
				packageName: toPackageName(APP_NAMES.INSIGHT_ASSETS_REPORTS_UNSUBSCRIBE),
				teamName: 'ITSM SAGA',
			},
		}
    })),
	createEntry(servicedeskCmdbAssetsObjectSchema1Route, () => ({
        ...sharedRouteProps,
        component: ObjectSchemaV2,
        resources: getCmdbResources(),
        forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbObjectSchemaV2],
        perfMetricKey: 'service-management.insight-cmdb.load-schema-page'
    })),
	createEntry(servicedeskCmdbInsightObjectSchemaRoute, () => ({
        ...sharedRouteProps,
        component: ObjectSchemaV2,
        resources: getCmdbResources(),
        forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbObjectSchemaV2],
        perfMetricKey: 'service-management.insight-cmdb.load-schema-page'
    })),
	createEntry(servicedeskCmdbAssetsObjectTypeConfigurationRoute, () => ({
        ...sharedRouteProps,
        component: ConfigureObjectType,
        resources: getCmdbResources(),
        forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbConfigureObjectType],

        meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_OBJECT_TYPE_CONFIGURATION,
				packageName: toPackageName(APP_NAMES.INSIGHT_OBJECT_TYPE_CONFIGURATION),
				teamName: 'falcons',
			},
		},

        ufoName: 'service-management.insight-cmdb-object-type-configuration'
    })),
	createEntry(servicedeskCmdbInsightObjectTypeConfigurationRoute, () => ({
        ...sharedRouteProps,
        component: ConfigureObjectType,
        resources: getCmdbResources(),
        forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbConfigureObjectType],

        meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_OBJECT_TYPE_CONFIGURATION,
				packageName: toPackageName(APP_NAMES.INSIGHT_OBJECT_TYPE_CONFIGURATION),
				teamName: 'falcons',
			},
		}
    })),
	createEntry(servicedeskCmdbAssetsConfigureRoute, () => ({
        ...sharedRouteProps,
        ufoName: 'servicedesk-cmdb-assets-configure',
        component: GlobalConfigure,
        resources: getCmdbResources(),
        forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbGlobalConfigure],
        perfMetricKey: 'service-management.insight-cmdb.interact-with-global-config-page',

        meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_GLOBAL_CONFIGURATION,
				packageName: toPackageName(APP_NAMES.INSIGHT_GLOBAL_CONFIGURATION),
				teamName: 'falcons',
			},
		}
    })),
	createEntry(servicedeskCmdbInsightConfigureGlobalRoute, () => ({
        ...sharedRouteProps,
        ufoName: 'servicedesk-cmdb-insight-configure-global',
        component: GlobalConfigure,
        resources: getCmdbResources(),
        forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbGlobalConfigure],
        perfMetricKey: 'service-management.insight-cmdb.interact-with-global-config-page',

        meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_GLOBAL_CONFIGURATION,
				packageName: toPackageName(APP_NAMES.INSIGHT_GLOBAL_CONFIGURATION),
				teamName: 'falcons',
			},
		}
    })),
	createEntry(servicedeskCmdbAssetsObjectSchemaConfigurationRoute, () => ({
        ...sharedRouteProps,
        component: ObjectSchemaConfiguration,
        resources: [...getCmdbResources(), serviceDeskAssetsImportTypeForgeDataResource],
        forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbObjectSchemaConfiguration],
        perfMetricKey: 'service-management.insight-cmdb.interact-with-object-schema-config',

        meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_GLOBAL_CONFIGURATION,
				packageName: toPackageName(APP_NAMES.INSIGHT_OBJECT_SCHEMA_CONFIGURATION),
				teamName: 'krispy-krew',
			},
		}
    })),
	createEntry(servicedeskCmdbInsightObjectSchemaConfigurationRoute, () => ({
        ...sharedRouteProps,
        component: ObjectSchemaConfiguration,
        resources: getCmdbResources(),
        forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbObjectSchemaConfiguration],
        perfMetricKey: 'service-management.insight-cmdb.interact-with-object-schema-config',

        meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_GLOBAL_CONFIGURATION,
				packageName: toPackageName(APP_NAMES.INSIGHT_OBJECT_SCHEMA_CONFIGURATION),
				teamName: 'krispy-krew',
			},
		}
    })),
	createEntry(servicedeskCmdbInsightConfigureRoute, () => ({
        ...sharedRouteProps,
        ufoName: 'servicedesk-cmdb-insight-configure',
        component: InsightConfigure,
        resources: getCmdbResources(),
        forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbConfigure]
    })),
	createEntry(servicedeskCmdbAssetsObject4Route, () => ({
        ...sharedRouteProps,
        component: InsightCmdbObjectViewV2,
        resources: [...getCmdbResources(), insightObjectResource],
        forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbObjectV2],
        perfMetricKey: 'service-management.insight-cmdb.object-view-page-load',
        ufoName: 'service-management.insight-cmdb.object-view-page-load'
    })),
	createEntry(servicedeskCmdbInsightObject4Route, () => ({
        ...sharedRouteProps,
        component: InsightCmdbObjectViewV2,
        resources: [...getCmdbResources(), insightObjectResource],
        forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbObjectV2],
        perfMetricKey: 'service-management.insight-cmdb.object-view-page-load',
        ufoName: 'service-management.insight-cmdb.object-view-page-load'
    })),
];
