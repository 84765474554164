import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { projectDetailsResource } from '@atlassian/jira-router-resources-business-project-details';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { softwareFormBuilderRoute } from '@atlassian/jira-router-routes-software-form-builder-routes';
import { LazyFormSubmitView } from '@atlassian/jira-router-routes-software-form-submit-entries/src/ui';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout';
import type { Route } from '@atlassian/react-resource-router';
import { LazyFormBuilderView, FormBuilderPage } from './ui';

export const softwareFormBuilderRouteEntry: Route = createEntry(softwareFormBuilderRoute, () => ({
    ufoName: 'next-gen-form-builder',
    component: componentWithFG('jwm_forms_to_jsw', FormBuilderPage, ErrorPagesNotFound),
    layout: softwareProjectLayout,

    navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
		horizontal: componentWithCondition(
			getWillShowNav4,
			AsyncHorizontalSoftwareProjectNav,
			() => null,
		),
	},

    resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		projectDetailsResource,
	],

    forPaint: [LazyAtlassianNavigation, LazyFormBuilderView],
    afterPaint: [LazyFormSubmitView]
}));
